// Dependencies
import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from 'gatsby';
import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import Moment from 'react-moment';

// Styles
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { getColorset } from '../components/PostCardv2';

// Components
import Layout from "components/Layout";
import LoadingAnimation from "components/LoadingAnimation";
import BackButton from "components/ui/BackButton";

// Title (h2)
const PostTitle = styled("div")`
    width: 80%;
    margin: 0 auto;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
    }

    h2 {
        width: 85%;

        @media (max-width: 960px) {
            width: 100%;
        }   
    }
`

// The date bar under the title
const PostInfo = styled("div")`
    width: 80%;
    margin: 0.75em auto 0.5em auto;
    border-top: 3px solid ${colors.grey200};
    
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
    }    
`

const PostDate = styled("div")`
    font-family: 'JetBrains Mono', 'Courier New', Courier, monospace;
    font-weight: 400; // Override Manrope's default 500
    
    background: ${colors.grey200};
    display: inline-block;
    padding: 0.2em 0.8em 0.2em 0.8em;
    color: ${colors.grey700};
    font-size: 0.8em;
`

/* HERO IMAGE STYLING (TODO) */
const PostHeroContainer = styled("div")`
    max-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3em;

    img {
        width: 100%;
    }
`
const PostHeroAnnotation = styled("div")`
    padding-top: 0.25em;

    h6 {
        font-size: 0.85em;
        font-weight: 400;
        
        text-align: right;
        color: ${colors.grey600};        
    }

    a {
        color: currentColor;
    }
`

// Contains text and sidebar
const PostContainer = styled("div")`
    width: 80%;
    margin: 0 auto;
    gap: 2.5em;
    
    display: flex;
    justify-content: flex-start;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
        gap: 0em;
    }
`

// Text
const PostBody = styled("div")`
    width: fill-available;

    .block-img {
        line-height: 0;
        
        img {
            width: 100%;
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }

    /* Styling links */
    a {
        text-decoration: none;
        transition: all 125ms ease-in-out; 
        color: ${colors.bqblue500};

        &:hover {
            cursor: pointer;
            transition: all 125ms ease-in-out;
            color: ${colors.bqblue600};    
            background-color: ${colors.bqblue200};
        }
    }
`

// Sidebar
const Sidebar = styled("div")`
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2em;

    // "Category"
    .categoryTitle {
        font-family: 'JetBrains Mono', 'Courier New', Courier, monospace;

        color: ${colors.grey600};

        font-size: 0.8em;
        font-weight: 400; // Override Manrope's default 500
        
        position: sticky;
        top: 6em;
    }

    // Category name
    h5 {
        margin: 0;
        position: sticky;
        top: 7.90em;
        
        // Highlighting that spans line-breaks
        span {
            display: inline;
            padding: 0 0.15em;
            line-height: 1.6;

            // Dynamically set color based on category
            color: ${(props) => getColorset(props.category)[0]};
            background: ${(props) => getColorset(props.category)[1]};
        }
    }

    @media (max-width: 960px) {
        display: none;
    }
`

const Post = ({ post, meta }) => {
    return (
        <>
            <Helmet
                title={`${post.post_title[0].text}`}
                titleTemplate={`%s – ${meta.title}`}
                meta={[
                    {
                        name: `description`,
                        content: `${post.post_preview_description[0].text}`,
                    },
                    {
                        property: `og:title`,
                        content: `${post.post_title[0].text} – ${meta.title}`,
                    },
                    {
                        property: `og:description`,
                        content: `${post.post_preview_description[0].text}`,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: meta.author,
                    },
                    {
                        name: `twitter:title`,
                        content: `${post.post_title[0].text} – ${meta.title}`,
                    },
                    {
                        name: `twitter:description`,
                        content: `${post.post_preview_description[0].text}`,
                    },
                ].concat(meta)}
            />

            <Layout>
                <LoadingAnimation>
                    
                    <PostTitle>
                        {RichText.render(post.post_title)}
                    </PostTitle>
                    
                    <PostInfo>
                        <PostDate>
                            <Moment format="MMM D, YYYY">{post.post_date}</Moment>
                        </PostDate>
                    </PostInfo>

                    {post.post_hero_image && (
                        <PostHeroContainer>
                            <img src={post.post_hero_image.url} alt="Post banner" />
                            
                            <PostHeroAnnotation>
                                {RichText.render(post.post_hero_annotation)}
                            </PostHeroAnnotation>
                        </PostHeroContainer>
                    )}

                    <PostContainer>

                        <PostBody>
                            
                            {RichText.render(post.post_body)}
                            <BackButton toPage={"/blog"} />

                        </PostBody>

                        <Sidebar category={post.post_category[0].text}>
                            <span class="categoryTitle">Category</span>
                            <h5>
                                <span>{post.post_category[0].text}</span>
                            </h5>
                        </Sidebar>

                    </PostContainer>
                    
                </LoadingAnimation>
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const postContent = data.prismic.allPosts.edges[0].node;
    const meta = data.site.siteMetadata;
    
    return (
        <Post post={postContent} meta={meta}/>
    );
}

// Ensure post prop types
Post.propTypes = {
    post: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
}

// GraphQL query
export const query = graphql`
    query PostQuery($uid: String) {
        prismic {
            allPosts(uid: $uid) {
                edges {
                    node {
                        post_title
                        post_hero_image
                        post_hero_annotation
                        post_date
                        post_category
                        post_body
                        post_author
                        post_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`